.menu-links {
    @apply flex justify-between items-center gap-7 md:static fixed z-50 bottom-0 md:w-auto w-screen md:bg-transparent bg-white left-0 md:border-t-0 border-t md:px-0 px-4 py-1
}

.menu-link {
    @apply flex flex-col items-center hover:text-[#40a9ff] transition-all gap-y-[2px]
}

.menu-link.active {
    @apply text-[#40a9ff]
}