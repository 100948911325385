@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');

body {
  margin: 0;
  font-family: 'Electrolize', sans-serif;
  @apply bg-gray-50;
}

.ant-btn-primary {
  background-color: #1677ff;
}

@media (min-width: 768px) {
  body {
    overflow: hidden;
    /* sayfada scroll olmasın diye */
  }

  .categories::-webkit-scrollbar {
    /* scrol gizli olsun diye */
    display: none;
  }
  
  .products::-webkit-scrollbar {
    display: none;
  }
}